import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// Components
import JaLayout from '../../../components/JaLayout';
import JaAboutBanner from '../../../components/JaAboutBanner';
import UnderConstruction from '../../../components/UnderConstruction';

// Images
import Hero from '../../../img/about_05_m_w.jpg';
import MobHero from '../../../img/about_05_m_h.jpg';

// Language
import { getPackOf } from '../../../lang';
import showdown from 'showdown';

const LANG = getPackOf('ja');
const converter = new showdown.Converter();

const AboutFacility = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/about-ja/facility.md" }) {
        frontmatter {
          disable
          sectionOne {
            title
            img {
              publicURL
            }
          }
          sectionOneContent
          sectionTwo {
            title
            img {
              publicURL
            }
          }
          sectionTwoContent
          sectionThree {
            title
            img {
              publicURL
            }
            bgImg {
              publicURL
            }
          }
          sectionThreeContent
          sectionFour {
            title
            img {
              publicURL
            }
          }
          sectionFourContent
          block {
            disable
            title
            description
            img {
              publicURL
            }
          }
        }
      }
    }
  `);

  const {
    markdownRemark: {
      frontmatter: {
        disable,
        sectionOne,
        sectionOneContent,
        sectionTwo,
        sectionTwoContent,
        sectionThree,
        sectionThreeContent,
        sectionFour,
        sectionFourContent,
        block,
      },
    },
  } = data;

  return (
    <JaLayout>
      <JaAboutBanner pageTitle={LANG.生產設備} subTitle={LANG.生產設備副標題} bgImg={Hero} mobileBgImg={MobHero} />
      {disable ? (
        <UnderConstruction />
      ) : (
        <>
          <section id="pro-1" className="py-5 bg-img">
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="col-12 col-md-5">
                  <img src={sectionOne.img.publicURL} alt="" className="img-thumbnail" />
                </div>
                <div className="col-12 col-md-7 align-self-center ">
                  <p className="h3 mb-4">{sectionOne.title}</p>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(sectionOneContent) }} />
                </div>
              </div>
            </div>
          </section>
          <section id="pro-2" className="py-5 bg-img bg-light">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7 align-self-center ">
                  <p className="h3 mb-4">{sectionTwo.title}</p>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(sectionTwoContent) }} />
                </div>
                <div className="col-12 col-md-5">
                  <img src={sectionTwo.img.publicURL} alt="" className="img-thumbnail" />
                </div>
              </div>
            </div>
          </section>
          <section
            id="pro-3"
            className="py-5 bg-img text-white"
            style={{ backgroundImage: ` url(${sectionThree.bgImg.publicURL})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5">
                  <img src={sectionThree.img.publicURL} alt="" className="img-thumbnail" />
                </div>
                <div className="col-12 col-md-7 align-self-center">
                  <p className="h3 mb-4">{sectionThree.title}</p>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(sectionThreeContent) }} />
                </div>
              </div>
            </div>
          </section>
          <section id="pro-4" className="py-5 bg-img bg-light">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7 align-self-center ">
                  <p className="h3 mb-4">{sectionFour.title}</p>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(sectionFourContent) }} />
                </div>
                <div className="col-12 col-md-5">
                  <img src={sectionFour.img.publicURL} alt="" className="img-thumbnail" />
                </div>
              </div>
            </div>
          </section>
          {block.map((e, i) => {
            if (e.disable) {
              return null;
            }

            if (i % 2 === 0) {
              return (
                <section id="pro-1" className="py-5 bg-img">
                  <div className="container">
                    <div className="d-flex flex-wrap">
                      <div className="col-12 col-md-5">
                        <img src={e.img.publicURL} alt="" className="img-thumbnail" />
                      </div>
                      <div className="col-12 col-md-7 align-self-center ">
                        <p className="h3 mb-4">{e.title}</p>
                        <div>{e.description}</div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            } else {
              return (
                <section id="pro-4" className="py-5 bg-img bg-light">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-7 align-self-center ">
                        <p className="h3 mb-4">{e.title}</p>
                        <div>{e.description}</div>
                      </div>
                      <div className="col-12 col-md-5">
                        <img src={e.img.publicURL} alt="" className="img-thumbnail" />
                      </div>
                    </div>
                  </div>
                </section>
              );
            }
          })}
        </>
      )}
    </JaLayout>
  );
};

export default AboutFacility;
